import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import clock from "../Tabs/clock.png";
import list from "../Tabs/list.png";
import settings from "../Tabs/settings.png";
import Tabs from "../Tabs/Tabs";
import Settings from "../Settings/Settings";
import List from "../List/List";
import EnterData from "../EnterData/EnterData";
import { useState, useEffect,  useRef } from "react";
import Splash from "../Splash/Splash";
import useUser from "./useUser";
import Login from "../Login/Login";
import configData from "./config.json";

export const globalUrl = configData.GLOBAL_URL;
const tabs = [
    {
        index: 1,
        text: "Mata in",
        image: clock,
        view: "/enter",
    },
    {
        index: 2,
        text: "Lista",
        image: list,
        view: "/list",
    },
    {
        index: 3,
        text: "Settings",
        image: settings,
        view: "/settings",
    },
];

function App() {
    const checkedToken=useRef(false)
    const [showLogin, setShowLogin] = useState(true);
    const { user, setUser } = useUser();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if(checkedToken.current) return;
        checkedToken.current=true
        checkToken(user)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return null;
                }
            })
            .then((data) => {
                if (data === null) {
                    setShowLogin(true);
                    setUser(data);
                    return;
                }
                if (data.token !== user.token) {
                    setUser(data);
                }
                setShowLogin(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    });

    if (isLoading) {
        return <Splash />;
    }
    if (showLogin) {
        return <Login setToken={setUser} />;
    }

    //    setShowLogin(false);
    let selectedIndex = 1;
    let href = tabs.find((t) => t.index === selectedIndex).view;
    if (window.location.pathname !== "/") {
        selectedIndex = tabs.find((t) => t.view === window.location.pathname)
            .index;
    }
    return (
        <BrowserRouter>
            <Tabs tabs={tabs} selectedIndex={1} />
            <Routes>
                <Route path="/" element={<Navigate to={href} />} />
                <Route  path="/enter" element={<EnterData />} />
                <Route path="/list" element={<List />} />
                <Route path="/settings" element={<Settings />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
async function checkToken(userCredentials) {
    let data;
    data = await fetch(globalUrl + "/checkToken/", {
        method: "POST",
        headers: {
            "Access-Control-Request-Method": "POST",
            "Access-Control-Request-Headers":
                "accesstoken, userid, Content-Type",
            "Content-Type": "application/json",
            accesstoken: userCredentials.token ?? "",
            userid: userCredentials.email ?? "",
        },
    });
    return data;
}
