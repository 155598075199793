import "./EnterData.css";
import { useState, useReducer, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import Moment from "moment";
import "react-input-range/lib/css/index.css";
import {
    getGroupHours,
    saveCoachHours,
    getTimeRecord,
    deleteCoachHours,
} from "../../services/coachHours";
import useUser from "../App/useUser";
import Loading from "../Loading/Loading";

const formReducer = (state, data) => {
    if (data.reset) {
        return {
            id: "",
            tid: Moment().set({ hour: 1, minute: 0 }).format("HH:mm"),
            slider: 60,
            datum: Moment().format("YYYY-MM-DD"),
            grupp: "",
            funktion: "L",
            beskrivning: "",
        };
    }
    if (data.name === "length") {
        data.value = data.value.replace(/\D/g, "");
    }
    return { ...state, [data.name]: data.value };
};

export default function EnterData() {
    const [record] = useSearchParams();
    const ItemId = record.get("id");

    const user = useUser().user;
    const groupsLoaded = useRef(false);
    const loadingRecord = useRef(false);
    const [submitting, setSubmitting] = useState(false);
    const [statusMeddelande, setStatusMeddelande] = useState("");
    const [formData, setFormData] = useReducer(formReducer, {
        id: "",
        tid: Moment().set({ hour: 1, minute: 0 }).format("HH:mm"),
        slider: 60,
        datum: Moment().format("YYYY-MM-DD"),
        grupp: "",
        funktion: "L",
        beskrivning: "",
    });
    const handleChange = (event) => {
        setFormData({
            name: event.target.name,
            value: event.target.value,
        });
        if (event.target.name === "slider") {
            const sliderValue = event.target.value;
            const timmar = Math.floor(sliderValue / 60);
            const minuter = sliderValue % 60;
            setFormData({
                name: "tid",
                value: Moment()
                    .set({ hour: timmar, minute: minuter })
                    .format("HH:mm"),
            });
        }
    };

    function saveData(event) {
        event.preventDefault();
        if (formData.grupp === "") {
            return false;
        }
        setSubmitting(true);
        saveCoachHours(user, formData)
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw response.json();
                }
            })
            .then((data) => {
                showToast("success", data.message);
                setFormData({ reset: true });
            })
            .catch(async (err) => {
                let meddelande = await err;
                showToast("error", meddelande.error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    }
    useEffect(() => {
        if (groupsLoaded.current) return;
        groupsLoaded.current = true;
        getGroupHours(user)
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw response.json();
                }
            })
            .then((data) => {
                while (document.getElementById("grupp").options.length > 1) {
                    document.getElementById("grupp").remove(1);
                }
                data.sort((a, b) => {
                    return a.hoursAsSeconds < b.hoursAsSeconds;
                });
                data.forEach((grupp) => {
                    let opt = new Option(grupp.name, grupp.id);
                    if (grupp.hoursAsSeconds > 0) {
                        document.getElementById("myGroups").append(opt);
                    } else {
                        document.getElementById("allGroups").append(opt);
                    }
                });
                if (ItemId !== null) {
                    loadRecord(ItemId);
                }
            })
            .catch(async (err) => {
                let meddelande = await err;
                if (meddelande.error[0] === "Ogiltigt token") {
                    window.location.href = "/";
                }
            });
    });

    function loadRecord(id) {
        if (loadingRecord.current) return;
        loadingRecord.current = true;
        getTimeRecord(user, id)
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw response.json();
                }
            })
            .then((data) => {
                for (let itm in data) {
                    setFormData({ name: itm, value: data[itm] });
                }
            })
            .catch(async (err) => {
                let meddelande = await err;
                if (meddelande.error[0] === "Ogiltigt token") {
                    window.location.href = "/";
                }
            })
            .finally(() => {
                loadingRecord.current = false;
            });
    }

    function removeRecord(event) {
        event.preventDefault();
        setSubmitting(true);
        deleteCoachHours(user, formData.id)
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw response.json();
                }
            })
            .then((data) => {
                showToast("success", data.message);
                setFormData({ reset: true });
            })
            .catch(async (err) => {
                let meddelande = await err;
                showToast("error", meddelande.error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    }
    function showToast(cls, meddelande) {
        let toast = document.getElementById("toast");
        toast.className = cls;
        if (Array.isArray(meddelande)) {
            setStatusMeddelande(meddelande.join("<br>"));
        } else if (typeof meddelande === "object") {
            setStatusMeddelande(JSON.stringify(meddelande));
        } else {
            setStatusMeddelande(meddelande);
        }
        setTimeout(() => {
            toast.className = "hidden";
        }, 5000);
    }

    if (loadingRecord.current) {
        return <Loading />;
    }

    return (
        <form>
            <input name="id" type="hidden" value={formData.id} readOnly />
            <fieldset>
                <legend>Tid</legend>
                <input
                    type="range"
                    id="timeSlider"
                    name="slider"
                    min={0}
                    max={235}
                    step={15}
                    value={formData.slider}
                    onChange={handleChange}
                />
                <input
                    type="time"
                    id="timeValue"
                    size="5"
                    name="tid"
                    readOnly=""
                    value={formData.tid}
                    onChange={handleChange}
                />
            </fieldset>
            <fieldset>
                <legend>Datum</legend>
                <input
                    type="date"
                    id="date"
                    name="datum"
                    value={formData.datum}
                    onChange={handleChange}
                />
                {" "}
                <input
                    type="button"
                    id="minusEtt"
                    value="-1 dag"
                    onClick={() => {
                        setFormData({
                            name: "datum",
                            value: Moment(formData.datum)
                                .subtract(1, "days")
                                .format("YYYY-MM-DD"),
                        });
                    }}
                />{" "}
                <input
                    type="button"
                    id="igar"
                    value="Igår"
                    onClick={() => {
                        setFormData({
                            name: "datum",
                            value: Moment()
                                .subtract(1, "days")
                                .format("YYYY-MM-DD"),
                        });
                    }}
                />{" "}
                <input
                    type="button"
                    id="idag"
                    value="Idag"
                    onClick={() => {
                        setFormData({
                            name: "datum",
                            value: Moment().format("YYYY-MM-DD"),
                        });
                    }}
                />
            </fieldset>
            <fieldset>
                <legend>Grupp</legend>
                <select
                    name="grupp"
                    id="grupp"
                    onChange={handleChange}
                    value={formData.grupp}
                >
                    <option hidden={true}>Välj grupp</option>
                    <optgroup id="myGroups">Använda grupper</optgroup>
                    <optgroup id="allGroups">Övriga grupper</optgroup>
                </select>
                <label>
                    <input
                        type="radio"
                        name="funktion"
                        value="L"
                        onChange={handleChange}
                        checked={formData.funktion === "L"}
                    />{" "}
                    Ledare{" "}
                    <input
                        type="radio"
                        name="funktion"
                        value="A"
                        onChange={handleChange}
                        checked={formData.funktion === "A"}
                    />{" "}
                    Assistent
                </label>
            </fieldset>
            <fieldset>
                <legend>Anm.</legend>
                <textarea
                    name="beskrivning"
                    id="beskrivning"
                    rows="4"
                    placeholder="Övrig info..."
                    onChange={handleChange}
                    value={formData.beskrivning}
                />
            </fieldset>
            <input
                type="button"
                id="spara"
                value="Spara"
                disabled={formData.grupp === "" || submitting}
                onClick={(e) => saveData(e)}
            />{" "}
            <input
                type="button"
                id="radera"
                value="Radera"
                disabled={formData.id === "" || submitting}
                onClick={(e) => removeRecord(e)}
            />{" "}
            <input
                type="button"
                id="ny"
                value="Ny"
                disabled={formData.grupp === "" || submitting}
                onClick={() => setFormData({ reset: true })}
            />
            <div id="toast" className="hidden">
                <p dangerouslySetInnerHTML={{ __html: statusMeddelande }} />
            </div>
        </form>
    );
}
