import { useEffect, useRef, useState } from "react";
import "./List.css";
import Loading from "../Loading/Loading";
import lock from "./lock.png";
import money from "./money.png";
import useUser from "../App/useUser";
import { getCoachHours } from "../../services/coachHours";

export default function List() {
    const user = useUser().user;
    const [loading, setLoading] = useState(true);
    const listLoaded = useRef(false);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if (listLoaded.current) return;
        listLoaded.current = true;
        getCoachHours(user)
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw response.json();
                }
            })
            .then((data) => {
                data.sort((a, b) => {
                    return a.date < b.date;
                });
                setTableData(data);
            })
            .catch(async (err) => {
                let meddelande = await err;
                if (meddelande.error[0] === "Ogiltigt token") {
                    window.location.href = "/";
                }
            })
            .finally(() => {
                setLoading(false);
            });
    });

    if (loading) {
        return <Loading />;
    }

    return (
        <div id="wrapper">
            <table>
                <thead>
                    <tr>
                        <th>Datum</th>
                        <th>Tid</th>
                        <th colSpan="2">Grupp</th>
                    </tr>
                </thead>
                <tbody>
                    {tableData.map((itm) => {
                        return (
                            <tr
                                key={itm.id}
                                className={itm.paid || itm.locked ? "" : "link"}
                                onClick={
                                    itm.paid || itm.locked
                                        ? (e) => {}
                                        : (e) => {
                                              window.location.href =
                                                  "/enter?id=" + itm.id;
                                          }
                                }
                            >
                                <td>{itm.date}</td>
                                <td>{itm.time}</td>
                                <td>{itm.groupname}</td>
                                <td>
                                    {itm.paid ? (
                                        <img
                                            src={money}
                                            alt={itm.paid}
                                            height="16"
                                        />
                                    ) : itm.locked ? (
                                        <img
                                            src={lock}
                                            alt={itm.locked}
                                            height="16"
                                        />
                                    ) : (
                                        ""
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}
