import moment from "moment";
import { useRef } from "react";
import "./Settings.css";

export default function Settings() {
    const user = useRef({});
    const versionDate = moment(document.lastModified).format('YYYY-MM-DD');
    user.current = JSON.parse(localStorage.getItem("user"));
    return (
        <div id="settings">
            <p>
                Inloggad som:
                <br />
                <span>
                    {user.current.firstname} {user.current.lastname}{" "}
                </span>
            </p>
            <button
                onClick={() => {
                    localStorage.removeItem("user");
                    window.location.href = "/";
                }}
            >
                Logga ut
            </button>
            <p>
                Version:
                <br />
                <span>1.0</span>
                <br />
                <small>({versionDate})</small>
            </p>
        </div>
    );
}
