import { globalUrl } from "../components/App/App";

export function getGroupHours(user) {
    const url = globalUrl + "/groupHours/";
    return fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Request-Method": "GET",
            "Access-Control-Request-Headers":
                "userid, accesstoken, Content-Type",
            userid: user.email,
            accesstoken: user.token,
        },
    });
}

export function saveCoachHours(user, formData) {
    const url = globalUrl + "/coachHours/";
    if (formData.id === "") {
        return fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Request-Method": "POST",
                "Access-Control-Request-Headers":
                    "userid, accesstoken, Content-Type",
                userid: user.email,
                accesstoken: user.token,
            },
            body: JSON.stringify(formData),
        });
    } else {
        return fetch(url, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Request-Method": "PUT",
                "Access-Control-Request-Headers":
                    "userid, accesstoken, Content-Type",
                userid: user.email,
                accesstoken: user.token,
            },
            body: JSON.stringify(formData),
        });
    }
}

export function getCoachHours(user) {
    const url = globalUrl + "/coachHours/";
    return fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Request-Method": "GET",
            "Access-Control-Request-Headers":
                "userid, accesstoken, Content-Type",
            userid: user.email,
            accesstoken: user.token,
        },
    });
}

export function getTimeRecord(user, id) {
    const url = globalUrl + "/getTimelistRecord?id=" + id;
    return fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Request-Method": "GET",
            "Access-Control-Request-Headers":
                "userid, accesstoken, Content-Type",
            userid: user.email,
            accesstoken: user.token,
        },
    });
}

export function deleteCoachHours(user, id) {
    const url = globalUrl + "/coachHours/";
    return fetch(url, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Request-Method": "DELETE",
            "Access-Control-Request-Headers":
                "userid, accesstoken, Content-Type",
            userid: user.email,
            accesstoken: user.token,
        },
        body: JSON.stringify({ id: id }),
    });
}
