import { useState } from "react";
import "./Login.css";
import PropTypes from "prop-types";
import { globalUrl } from "../App/App";

export default function Login({ setToken }) {
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [statusMeddelande, setStatusMeddelande] = useState("");

    async function loginUser(credentials) {
        const data = await fetch(globalUrl + "/login/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(credentials),
        });
        return data;
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        loginUser({ username, password }).then(async (response) => {
            if (response.ok) {
                setToken(await response.json());
                window.location.href = "/";
            } else {
                const err = await response.json();
                showToast("error", err.error.join("<br />"));
                document.getElementById("loginFailed").style = "display:block;";
            }
        });
    };

    function showToast(cls, meddelande) {
        let toast = document.getElementById("toast");
        toast.className = cls;
        if (Array.isArray(meddelande)) {
            setStatusMeddelande(meddelande.join("<br>"));
        } else if (typeof meddelande === "object") {
            setStatusMeddelande(JSON.stringify(meddelande));
        } else {
            setStatusMeddelande(meddelande);
        }
        setTimeout(() => {
            toast.className = "hidden";
        }, 5000);
    }

    return (
        <div className="login-wrapper">
            <h1>Logga in!</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    <p>Användare</p>
                    <input
                        type="text"
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </label>
                <label>
                    <p>Lösen</p>
                    <input
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </label>
                <div>
                    <button type="submit">Logga in</button>
                </div>
            </form>
            <div id="toast" className="hidden">
                <p dangerouslySetInnerHTML={{ __html: statusMeddelande }} />
            </div>
        </div>
    );
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired,
};
